@import url(https://fonts.googleapis.com/css?family=Yellowtail&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap);
.Toastify__toast--success{
  background: #0b1142 !important;
}

.MuiAutocomplete-groupLabel {
  font-weight: 700 !important;
}


@font-face {
  font-family: 'Paynow-font Regular';
  src: url(/static/media/1a930247.1e76c644.woff2);
}
/* @font-face {
  font-family: 'Paynow-font Demi';
  src: url('./assests/fonts/AvenirNextLTPro-Demi.otf');
}
@font-face {
  font-family: 'Paynow-font Medium';
  src: url('./assests/fonts/AvenirNextLTPro-Medium.otf');
}
@font-face {
  font-family: 'Paynow-font Bold';
  src: url('./assests/fonts/AvenirNextLTPro-Bold.otf');
} */

